<template>
  <v-simple-table class="pt-4">
    <template v-if="mode == 'Sale'" v-slot:default>
      <thead>
        <tr>
          <th class="text-center display-1 font-weight-bold" colspan="9">
            {{ title }}
          </th>
        </tr>

        <tr>
          <th class="text-center">Month</th>
          <th class="text-center"></th>
          <th class="text-center">United State Dollar (USD)</th>
          <th class="text-center">Renminbi (RMB)</th>
        </tr>
      </thead>

      <tbody>
        <tr class="text-center">
          <td rowspan="4">
            {{ datas.Month }}
          </td>
          <td>Total Sale</td>
          <td>
            {{ formatNumber(datas.USD.sale) }}
          </td>
          <td>
            {{ formatNumber(datas.RMB.sale) }}
          </td>
        </tr>

        <tr class="text-center">
          <td>Total Order</td>
          <td>
            {{ formatNumber(datas.USD.order) }}
          </td>
          <td>
            {{ formatNumber(datas.RMB.order) }}
          </td>
        </tr>

        <tr class="text-center">
          <td>Total Amount</td>
          <td>
            {{ formatNumber(datas.USD.amount) }}
          </td>
          <td>
            {{ formatNumber(datas.RMB.amount) }}
          </td>
        </tr>

        <tr class="text-center">
          <td>Customer Qty</td>
          <td>
            {{ formatNumber(datas.USD.customer) }}
          </td>
          <td>
            {{ formatNumber(datas.RMB.customer) }}
          </td>
        </tr>
      </tbody>
    </template>

    <template v-else v-slot:default>
      <thead>
        <tr>
          <th class="text-center display-1 font-weight-bold" colspan="9">
            {{ title }}
          </th>
        </tr>
        <tr>
          <th class="text-center">Month</th>
          <th class="text-center">Total Sale</th>
          <th class="text-center">Total Order</th>
          <th class="text-center">Total Amount</th>
          <th class="text-center">Number of Supplier</th>
          <th class="text-center">Sale Discount RMB</th>
          <th class="text-center">Shipping</th>
          <th class="text-center">Profit</th>
        </tr>
      </thead>

      <tbody>
        <tr class="text-center">
          <td>
            {{ datas.Month }}
          </td>
          <td>
            {{ formatNumber(datas.sale) }}
          </td>
          <td>
            {{ formatNumber(datas.order) }}
          </td>
          <td>
            {{ formatNumber(datas.amount) }}
          </td>
          <td>
            {{ formatNumber(datas.supplier) }}
          </td>
          <td>
            {{ formatNumber(datas.saleDiscount) }}
          </td>
          <td>
            {{ formatNumber(datas.shipping) }}
          </td>
          <td>
            {{ formatNumber(datas.profit) }}
          </td>
        </tr>
      </tbody>
    </template>
  </v-simple-table>
</template>

<script>
export default {
  name: "StatisticalTableCost",
  props: {
    datas: Object,
    title: String,
    mode: String,
  },
};
</script>

<style lang="sass">
tr th
  border-bottom: 0.5px solid lightgrey

tbody
  tr:hover
    background-color: transparent !important
</style>
